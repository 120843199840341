
import { Component, Vue } from "vue-property-decorator";
import {
    mdiCellphoneBasic,
    mdiLightbulbOnOutline,
    mdiLoginVariant,
    mdiCheckCircle,
    mdiTelevisionClassic,
    mdiWaterPump,
    mdiTransfer,
    mdiTelevisionShimmer,
} from "@mdi/js";
import ServiceProvided, { ServiceItem } from "@/components/ServiceProvided.vue";
import SocialButtons from "@/components/SocialButtons.vue";
import TwitterCard from "@/components/TwitterCard.vue";
import { Getter } from "vuex-class";
import { addAnalyticsEvent } from "@/plugins/firebase";
import { isInStandaloneMode, isIos, isWebView } from "@/plugins/utils";
import { siFacebook, siInstagram, siTiktok, siX } from "simple-icons";
@Component({
    components: { TwitterCard, SocialButtons, ServiceProvided },
})
export default class LandingPageHome extends Vue {
    loginIcon: string = mdiLoginVariant;
    electricityIcon: string = mdiLightbulbOnOutline;
    waterIcon: string = mdiWaterPump;
    payIcon: string = mdiTelevisionClassic;
    canalplusIcon: string = mdiTelevisionShimmer;
    exchangeIcon: string = mdiTransfer;
    mdiCheckCircle: string = mdiCheckCircle;
    phoneIcon: string = mdiCellphoneBasic;
    twitterIcon = siX;
    tiktokIcon = siTiktok;
    facebookIcon = siFacebook;
    instagramIcon = siInstagram;

    @Getter("isLoggedIn") isLoggedIn!: boolean;
    @Getter("navRoute") navRoute!: string | null;

    get color(): string {
        if (this.$vuetify.theme.dark) {
            return "#121212";
        }

        if (this.isWebView) {
            return "#212121";
        }

        return "#FAFAFA";
    }

    get standalone(): boolean {
        return isInStandaloneMode();
    }

    get isIos() {
        return isIos();
    }

    get isWebView() {
        return isWebView();
    }

    get androidAppUrl(): string {
        return process.env.VUE_APP_ANDROID_APP_URL as string;
    }

    get iosAppUrl(): string {
        return process.env.VUE_APP_IOS_APP_URL as string;
    }

    get services(): Array<ServiceItem> {
        return [
            {
                name: "DStv Subscription",
                buttonText: "Pay DStv Subscription",
                text: "Select your DStv package e.g Compact Plus and pay using your smartcard number.",
                icon: this.payIcon,
                route: { name: this.$constants.ROUTE_NAMES.DSTV_INDEX },
            },
            {
                name: "Airtime",
                buttonText: "Buy Airtime",
                text: "Buy Camtel, MTN, Orange, neXttel and YooMee airtime for your phone instantly.",
                icon: this.phoneIcon,
                route: { name: this.$constants.ROUTE_NAMES.AIRTIME_INDEX },
            },
            {
                name: "CANAL+",
                buttonText: "Pay CANAL+ Subscription",
                text: "Pay your CANAL+ subscription using MTN Mobile Money, Orange Money or Bank Card.",
                icon: this.canalplusIcon,
                route: { name: this.$constants.ROUTE_NAMES.CANALPLUS_INDEX },
            },
            {
                name: "Transfer Money",
                buttonText: "Transfer Money",
                text: "Transfer money from your MTN Mobile Money into an Orange Money account or vice versa",
                icon: this.exchangeIcon,
                route: { name: this.$constants.ROUTE_NAMES.EXCHANGE_INDEX },
            },
            {
                name: "ENEO",
                buttonText: "Pay ENEO Bill",
                text: "Pay your electricity bill for both prepaid and postpaid ENEO meters instantly.",
                icon: this.electricityIcon,
                route: { name: this.$constants.ROUTE_NAMES.ENEO_INDEX },
            },
            {
                name: "Camwater",
                buttonText: "Pay Camwater Bill",
                text: "Pay your camwater instantly using the 10 digit bill number at the top of your camwater bill.",
                icon: this.waterIcon,
                route: { name: this.$constants.ROUTE_NAMES.CAMWATER_INDEX },
            },
        ];
    }

    mounted() {
        if (this.navRoute) {
            this.$router.push({ name: this.navRoute });
            return;
        }

        if (this.isLoggedIn) {
            this.$router.push({ name: this.$constants.ROUTE_NAMES.DSTV_INDEX });
            return;
        }

        if (this.$vuetify.breakpoint.mdAndDown) {
            this.$root.$emit(this.$constants.DRAWER_EVENTS.HIDE);
        }
    }

    registerCta(event: string) {
        addAnalyticsEvent(event);
    }
}
